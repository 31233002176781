<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name_en }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row v-if="listAnnual.length > 0">
                <v-col sm="4" cols="12" v-for="(ann, i) in listAnnual" :key="i">
                  <v-card
                    color="#fccece"
                    dark
                    max-width="500"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'MajorAdminAnnualScore',
                        params: {
                          session_ids: JSON.stringify(ann.session_ids),
                          annual_id: ann.annual_id,
                        },
                      })
                    "
                  >
                    <v-img
                      src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fbg4.jpg?alt=media&token=b694d08d-af07-4484-aa6f-b5ccc3ffff7e"
                      width="500"
                      height="150"
                    >
                      <div align="center" justify="center" class="mt-8">
                        <div class="text-h5 text-center">Annual Score</div>
                        <div class="text-h5 text-center">For</div>

                        <div class="text-h5 text-center">{{ ann.name_en }}</div>
                      </div>
                      <router-link
                        style="text-decoration: none"
                        :to="
                          'major-admin/annual/gen/score/studentscore/' +
                          JSON.stringify(ann.session_ids) +
                          '&' +
                          ann.annual_id
                        "
                        tag="button"
                      >
                      </router-link>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else align="center" justify="center" class="mb-5">
                <div>
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                    class="center"
                  ></v-img>
                  <div class="text-h6 text-center">
                    There is no data for you to see yet
                  </div>
                  <div class="text-h6 text-center">
                    Oops, No annual scores found.
                  </div>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import { getAnnual } from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    isLoading: false,
    token: store.getters.getToken,
    listAnnual: [],
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    items: [
      {
        text: "Generation",
        disabled: false,
        href: "javascript:history.go(-1)",
      },
      {
        text: "Annual Score",
        disabled: true,
      },
    ],

    myLoading: false,
  }),
  computed: {},
  methods: {
    async loadAnnual() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getAnnual(
          this.$route.params.major_id,
          this.$route.params.id,
          this.token
        );

        this.listAnnual = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.loadAnnual();
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
}
.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}
</style>
